const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/shop-by-brand';

function toggleSearchInput(e) {
  if (
    e.relatedTarget?.classList.contains('cm_search-box-root') ||
    e.relatedTarget?.classList.contains('cm_search-box_submit')
  ) {
    return;
  }

  const animationDuration = 150;

  const searchBoxContainer = window.document.querySelector('.cm_search-box-root_container');
  searchBoxContainer.style.setProperty('--animation-duration', `${animationDuration}ms`);
  searchBoxContainer.classList.toggle('focused');

  if (searchBoxContainer.classList.contains('focused')) {
    searchBoxContainer.classList.add('absolute');

    const computedWidth =
      window.document.querySelector('.cm_vehicle-widget__header_desktop').getBoundingClientRect().right -
      searchBoxContainer.getBoundingClientRect().left;
    searchBoxContainer.style.width = `${computedWidth}px`;
  } else {
    searchBoxContainer.style.removeProperty('width');

    setTimeout(() => {
      searchBoxContainer.classList.remove('absolute');
    }, animationDuration);
  }
}

function AfterInit() {
  const searchInput = window.document.querySelector('#cm_SearchBox input');
  searchInput.addEventListener('focus', toggleSearchInput);
  searchInput.addEventListener('focusout', toggleSearchInput);
}

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

export default {
  platform: 'shopify',
  AfterInit,
  SearchRequestDefaults: {
    pageSize: window.Convermax.config?.pageSize || 24,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: window.Convermax.config?.categorySelectionPageRedirect ?? true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        replace: 'body.template-collection #MainContent .collection-header + .page-width',
        class: 'page-width',
      },
      template: 'SearchPage',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: 'body.template-list-collections #MainContent .page-width + section .page-width',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'A to Z',
        'title:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
      sortSelectClass: 'toolbar_sort_by-block filters-toolbar__input',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchBox',
      location: {
        replace: '.search-form__container',
        class: 'search-form__container cm_search-box-root_container',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: '.header-search-button', class: 'header-search-button', wrapper: 'button' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        insertAfter: '.site-header__icons-wrapper .search-bar__interior',
        class: 'cm_vehicle-widget__header_desktop cm_mobile-hide',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 0,
    },
    {
      name: 'HomeVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: {
        insertAfter: '.index-section--slideshow',
        class: 'cm_vehicle-widget__home_mobile cm_desktop-hide page-width',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 800,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.header_cart_info',
        class: 'header_cart_info btn btn--primary show_cart_subtotal',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      location: { insertAfter: 'body.template-product .inventory_qty_info' },
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 600,
    },
    {
      name: 'FitmentTable',
      location: '.product_description_accrordion__Fitment .product_accordion__content .rte',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'RelatedItems',
      location: {
        replace: '[data-section-type="product-recommendations"]',
        class: 'page-width zoom-fade-animation half_row_mobile zoomFade-animation',
      },
      template: 'product/relatedItems',
      count: 4,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      location: { class: 'vehicle-dialog' },
      template: 'fitmentSearch/dialog',
    },
  ],
};
