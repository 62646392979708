export default {
  BUTTON_PRIMARY_CLASS: 'btn btn--primary',
  BUTTON_SECONDARY_CLASS: 'btn btn--secondary',

  SEARCH_BOX_CLASS: 'search-form search-bar__form',
  SEARCH_BOX_FORM_CLASS: 'search-form__input-wrapper',
  SEARCH_BOX_INPUT_CLASS: 'search-form__input search-bar__input is-filled',
  SEARCH_BOX_BUTTON_CLASS: 'search-button__submit search-form__submit',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',
  BROWSE_ALL: 'View All Results',

  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',
  FACET_TITLE_ICON: '<rt-virtual template="icons/angle-down" />',

  PRODUCT_REPEATER_CLASS:
    'grid grid--uniform grid--view-items use_align_height zoom-fade-animation zoomFade-animation',

  GARAGE_ICON_CONTAINER_CLASS: 'btn btn--primary',
  GARAGE_SIZE: '<span class="header_cart_count garage-size">{{size}}</span>',
};
